'use client';

import { useEffect } from 'react';
import { Flags } from '../flagConstants';
import { useExposureEvent } from './client';

/**
 * Triggers an exposure event for the given flag.
 * Done as a client component rather than as just a hook so we can import it and use it on app router pages. (Can't use hooks on app router pages without them becoming a client component themselves.)
 */
export const ExposureEvent = ({ flagName }: { flagName: Flags }) => {
  const { exposureEvent, value } = useExposureEvent(flagName);

  useEffect(() => {
    if (value) {
      exposureEvent();
    }
  }, [value, exposureEvent]);

  return null;
};
